// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn2 {
  display: inline-block;
  font-weight: .btn2-font-weight;
  color: $body-color;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: .btn2-border-width solid transparent;
  @include button-size(.btn2-padding-y, .btn2-padding-x, .btn2-font-size, .btn2-line-height, .btn2-border-radius);
  @include transition(.btn2-transition);

  @include hover {
    color: $body-color;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: .btn2-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: .btn2-disabled-opacity;
    @include box-shadow(none);
  }

  // Opinionated: add "hand" cursor to non-disabled .btn2 elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow(.btn2-active-box-shadow);

    &:focus {
      @include box-shadow(.btn2-focus-box-shadow, .btn2-active-box-shadow);
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn2.disabled,
fieldset:disabled a.btn2 {
  pointer-events: none;
}


//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn2-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn2-outline-#{$color} {
    @include button-outline-variant($value);
  }
}


//
// Link buttons
//

// Make a button look and behave like a link
.btn2-link {
  font-weight: $font-weight-normal;
  color: $link-color;

  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: .btn2-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}


//
// Button Sizes
//

.btn2-lg {
  @include button-size(.btn2-padding-y-lg, .btn2-padding-x-lg, .btn2-font-size-lg, .btn2-line-height-lg, .btn2-border-radius-lg);
}

.btn2-sm {
  @include button-size(.btn2-padding-y-sm, .btn2-padding-x-sm, .btn2-font-size-sm, .btn2-line-height-sm, .btn2-border-radius-sm);
}


//
// Block button
//

.btn2-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn2-block {
    margin-top: .btn2-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn2-block {
    width: 100%;
  }
}
